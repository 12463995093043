import { React, useState, useEffect } from 'react';
import data from "../site.json"
import { Text, Spacer, Image, Radio, Grid, Row, Button } from "@nextui-org/react";

const NOW_URL = "https://sentimentana.s3.eu-central-1.amazonaws.com/public/site.json"
const NOW_URLMETHOD = "GET"
const WORDCLOUD = "https://sentimentana.s3.eu-central-1.amazonaws.com/public/wordcloud.png"

function Home() {
  const [nowData, setNowData] = useState(data);
  const [nowDataNew, setNowDataNew] = useState(data);
  const [radioArray, setRadioArray] = useState([1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31]);
  const [isMobile, setIsMobile] = useState(false);
  const [topic, setTopic] = useState("https://");
  const [topicComment, setTopicComment] = useState("");
  const [votetime, setVotetime] = useState(45);
  const [radioDisabled, setRadioDisabled] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(6);

  const getTime = async () => {
    const time = Date.now();
    setVotetime(45 - Math.floor((time / 1000 / 60) % 60))

    if (votetime > 0) {
      setRadioDisabled(false)
    } else {
      setRadioDisabled(true)
      setRefreshCounter(refreshCounter - 1)
      if (refreshCounter < 0) {
        setRefreshCounter(6)
        download()
      }
    }
    let t = setTimeout(function () { getTime() }, 10000);
  };

  function getClosingText() {
    var v = 45
    try { // we need this on reload
      v = votetime
    } catch { }
    if (v > 0) {
      return <span>open, closes in <span className='timecount'>{v}</span> minutes.</span> 
    }
    v = 15 + v
    if (v == 1) {
      return <span>closed, reoping in <span className='timecount'>{v}</span> minute.</span> 
    }
    return <span>closed, reoping in <span className='timecount'>{v}</span> minutes.</span> 
  }

  function changeTopic(e) {
    setTopic(e.target.value)
  }

  function getRadio(key) {
    return radioArray[key];
  }

  const setRadio = (s) => {
    const i = parseInt(s / 3)
    let arrayCopy = JSON.parse(JSON.stringify(radioArray));
    arrayCopy[i] = s
    setRadioArray(arrayCopy)
    const sel = s % 3
    const obj = nowData[i]
    const oldSel = radioArray[i] % 3
    const url = obj.url
    var vote = 0;
    if (sel == 2 && oldSel == 1) {
      vote = 1
    }
    if (sel == 2 && oldSel == 0) {
      vote = 2
    }
    if (sel == 0 && oldSel == 2) {
      vote = -2
    }
    if (sel == 0 && oldSel == 1) {
      vote = -1
    }
    if (sel == 1 && oldSel == 2) {
      vote = -1
    }
    if (sel == 1 && oldSel == 0) {
      vote = 1
    }
    if (vote == 0) {
      console.log("Vote should not be 0")
      return;
    }
    nowData[i].vote += vote
    sendVote(url, vote)
  };

  // =======================================================================
  const addTopic = () => {
    var t = topic.trim()

    // check protocol is https
    if (!t.startsWith("https")) {
      setTopicComment("Please start with https")
      return
    }

    // check no spaces
    if (t.indexOf(' ') >= 0) {
      setTopicComment("No spaces")
      return
    }
    // check for dot
    if (t.indexOf('.') == -1) {
      setTopicComment("no domain?")
      return
    }

    // comment
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes();
    const comment = "Topic received at: " + time + ". Thank you!";
    setTopicComment(comment)

    // submit
    const rawResponse = fetch('https://api.sentimentana.com/api/v0/vote', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ v: "1.0.0", m: "addTopic", url: t })
    }).then(function (res) {
      res.json().then(function (content) {
        console.log(content);
      });
    });
  }

  // send vote =======================================================================
  const sendVote = async (url, vote) => {
    // submit
    const rawResponse = fetch('https://api.sentimentana.com/api/v0/vote', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ v: "1.0.0", m: "vote", url: url, vote: vote })
    }).then(function (res) {
      res.json().then(function (content) {
        console.log(content);
      });
    });
  }

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  async function download() {
    fetch(NOW_URL+"?id=" + new Date().getTime(), {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: NOW_URLMETHOD
    }).then(function (res) {
      res.json().then(function (data) {
        console.log(data)
        var i = 0
        var t = []
        for (i; i < 10; i++) {
          t.push(data[i])
        }
        setNowData(t)

        // new suggestions
        var t2 = []
        t2.push(data[10])
        setNowDataNew(t2)
      })
    })
      .catch(function (err) {
        console.log(err)
      })
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    download();
    getTime();
  }, []);

  {/* Header ======================================================================================================= */ }
  return (
    <main>
      <Grid.Container className="siteHeader">
        <Grid>
          <img className="mylogo" src="/logo.png" alt="Sentimentana Logo" />
        </Grid>
        <Grid>
          <span className="mytitle">Sentimentana</span>
        </Grid>
      </Grid.Container>
      <h3 className="teaser">Make your vote count!</h3>
      <h2 className="teaserSmall"> One vote per hour, poll {getClosingText()}</h2>
      <Image src={WORDCLOUD} alt="Sentimentana word cloud" />
      <h2 className="mysubtitle">Top 10 World Topics</h2>
      <div>
        <table className="topictable">
          {nowData &&
            Array.from(nowData).map(({ id, title, url, desc, fav, vote }) => (
              <>
                <tr key={id}>
                  <td align="left" rowSpan="3" className="topictable">
                    <a target="_blank" href={url}>
                      <img style={{ padding: "10px", float: "left" }} width="120" height="120" src={fav} alt={title.trim()} />
                    </a>
                  </td>
                  <td align="left"><a target="_blank" href={url}><div className="topicTitle">
                    {id + 1}. {title}</div></a></td>
                </tr>
                <tr>
                  <td valign='top'><a target="_blank" href={url}><div className="topicContent">{desc}</div></a></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className ="votingarea" colspan="3" align='bottom'><Row>
                    <Radio.Group onChange={setRadio} orientation="horizontal" value={radioArray[id]} name={id} defaultValue={id * 3 + 1} >
                      <Grid.Container  gap={1}>
                        <Grid> <span className="votecount" ><b>{vote} votes</b></span></Grid>
                        <Grid> <Radio className="votecountRadio" isDisabled={radioDisabled} labelColor="warning" color="warning" size="md" type="radio" value={id * 3} id={"d"}>not important</Radio></Grid>
                        <Grid> <Radio className="votecountRadio" isDisabled={radioDisabled} labelColor="primary" size="md" name={"idr" + id} type="radio" value={id * 3 + 1} id={"n"}>don't care</Radio></Grid>
                        <Grid> <Radio className="votecountRadio" isDisabled={radioDisabled} labelColor="success" color="success" size="md" type="radio" value={id * 3 + 2} id={"a"}>this topic is very important</Radio></Grid>
                      <Grid></Grid>
                      </Grid.Container>
                    </Radio.Group>
                    </Row>
                  </td>
                </tr>
                <tr><td colSpan="2"><hr /></td></tr>
              </>
            ))}
        </table>
      </div>

      {/* New Topic Section ============================================================*/}
      <div>
        <div className="trending">&nbsp;Trending:</div>
        {nowDataNew &&
          Array.from(nowDataNew).map(({ id, title, url, desc, fav,vote }) => (
            <div key={"idline" + id}>
              <table border="0" className="topictable">
                <tr>
                  <td className="trending" colSpan="2"><hr />

                  </td>
                </tr>
                <tr>
                  <td align="left" rowSpan="3" className="topictable">
                    <a target="_blank" href={url}>
                      <img style={{ padding: "10px", float: "left" }} width="120" height="120" src={fav} alt={title.trim()} />
                    </a>
                  </td>
                  <td align="left"><a target="_blank" href={url}><div className="topicTitle">{title}</div></a></td>
                </tr>
                <tr>
                  <td><a target="_blank" href={url}><div className="topicContent">{desc}</div></a></td>
                </tr>
               
                <tr>
                  <td></td>
                </tr>
                <tr>
                <td className ="votingarea" colspan="3" align='bottom'><Row>
                    <Radio.Group onChange={setRadio} orientation="horizontal" value={radioArray[id]} name={id} defaultValue={id * 3 + 1} >
                      <Grid.Container  gap={1}>
                        <Grid> <span className="votecount" ><b>{vote} votes</b></span></Grid>
                        <Grid> <Radio className="votecountRadio" isDisabled={radioDisabled} labelColor="warning" color="warning" size="md" type="radio" value={id * 3} id={"d"}>not important</Radio></Grid>
                        <Grid> <Radio className="votecountRadio" isDisabled={radioDisabled} labelColor="primary" size="md" name={"idr" + id} type="radio" value={id * 3 + 1} id={"n"}>don't care</Radio></Grid>
                        <Grid> <Radio className="votecountRadio" isDisabled={radioDisabled} labelColor="success" color="success" size="md" type="radio" value={id * 3 + 2} id={"a"}>this topic is very important</Radio></Grid>
                      <Grid></Grid>
                      </Grid.Container>
                    </Radio.Group>
                    </Row>
                  </td>
                </tr>
              </table>
            </div>
          ))}
      </div>

      {/* Topic Section ============================================================*/}

      <div gap={0} className="inputTopic">
        <Row className="inputTopicElement"><span className="inputTopicElement">Add a new topic</span></Row>
        <Row><input className="inputField" value={topic} onChange={changeTopic} />
          &nbsp;&nbsp;<Button style={{ height: "30px", width: "100px" }} onClick={addTopic}>Add Topic</Button></Row>
        <Row> &nbsp;&nbsp;&nbsp;<span className="topicComment">{topicComment}</span></Row>
      </div>

      {/* Sponsor Section ============================================================*/}

      <div className="row" style={{ width: "100%" }}><center><div className="sponsor">Thanks to our sponsors</div></center></div>
      <center>
        <table width={"60%"}>
          <tr>
            <td>
              <center>
                <div className="sponsorDiamant">
                  <Row><h1>Diamant:</h1></Row>
                  <Row><img alt={"logo"} src="https://static.wixstatic.com/media/463932_1a43e767abe548aab5fb7aa1321c5121~mv2.png/v1/fill/w_1046,h_186,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/tech41_header_large.png" height="50px" width="150px" /></Row>
                  <Row> <Text fontSize='1xl'>$5000</Text></Row>
                </div>
              </center>
            </td>
            <td className="column">
              <center>
                <div className="sponsorPlatinum">
                  <Row><Text fontSize='3xl'>Platinum:</Text></Row>
                  <Row><img alt={"logo"} src="https://static.wixstatic.com/media/463932_1a43e767abe548aab5fb7aa1321c5121~mv2.png/v1/fill/w_1046,h_186,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/tech41_header_large.png" width="150px" /></Row>
                  <Row> <Text fontSize='1xl'>$1000</Text></Row>
                </div>
              </center>
            </td>
            <td className="column">
              <center>
                <div className="sponsorGold">
                  <Row><Text fontSize='3xl'>Gold:</Text></Row>
                  <Row><img alt={"logo"} src="https://static.wixstatic.com/media/463932_1a43e767abe548aab5fb7aa1321c5121~mv2.png/v1/fill/w_1046,h_186,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/tech41_header_large.png" width="150px" /></Row>
                  <Row><Text fontSize='1xl'>$500</Text></Row>
                </div>
              </center>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <center>
                <div className="contact" >Contact: <a href="mailto:info@sentimentana.com" target="_blank"
                  rel="noopener noreferrer">info@sentimentana.com</a>
                  <Spacer />Copyright TECH41 GmbH, Berlin, 6.2.2023 - V 1.0.15
                </div>
              </center>
            </td>
          </tr>
        </table>
      </center>
    </main >
  );
}
export default Home;
