import { React, useState, useEffect } from 'react';
import { Text, Spacer, Image, Radio, Grid, Row, Input, Button, Textarea } from "@nextui-org/react";

const INSERT_TOPIC = "https://iam.auton.id/adminapi/v0/insertTopic"

function Topic() {
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("https://");
    const [fav, setFav] = useState("https://");
    const [desc, setDesc] = useState("some desc");
    const [rating, setRating] = useState(100);
    const [vote, setVote] = useState(100);
    const [used, setUsed] = useState(false);
    const [comment, setComment] = useState("ok");


    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIxOGEwMWZmNS1lNmE2LTQyNTctODgwYS1iOTdmYmM2OGJmZjEifQ.eyJleHAiOjAsImlhdCI6MTY3NTUwNzQzNywianRpIjoiNzQ2OTdiZmMtNDY0Zi00NDVhLTg4ZTktYzFhZWQ4NmMwODNhIiwiaXNzIjoiaHR0cHM6Ly9rZXkuYXV0b24uaWQ6ODQ0My9yZWFsbXMvc2VudGltZW50YW5hIiwiYXVkIjoiaHR0cHM6Ly9rZXkuYXV0b24uaWQ6ODQ0My9yZWFsbXMvc2VudGltZW50YW5hIiwidHlwIjoiUmVnaXN0cmF0aW9uQWNjZXNzVG9rZW4iLCJyZWdpc3RyYXRpb25fYXV0aCI6ImF1dGhlbnRpY2F0ZWQifQ.vmWxBV8Z8Znb0j0hnaayGyltbWlzSVoGYmrsLO67FmM'

    useEffect(() => {
        //getHeaders()
    }, []);

    async function getHeaders() {
        var req = new XMLHttpRequest();
        req.open('GET', document.location, false);
        req.send(null);
        var headers = req.getAllResponseHeaders().toLowerCase();
    }

    async function insertTopic() {
        if (title.length < 2) {
            setComment(JSON.stringify("Title not correct "))
            return;
        }
        if (url.length < 10) {
            setComment(JSON.stringify("URL not correct "))
            return;
        }
        if (fav.length < 10) {
            setComment(JSON.stringify("URL FAV not correct "))
            return;
        }
        if (desc.length < 5) {
            setComment(JSON.stringify("Description not correct "))
            return;
        }
        setComment("Updating Db.....")
        var topic = {}
        topic.title = title
        topic.url = url
        topic.fav = fav
        topic.desc = desc
        topic.rating = rating
        topic.vote = vote
        topic.used = used;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ topic: topic })
        };
        const response = await fetch(INSERT_TOPIC, requestOptions);
        const data = await response.json();
        setComment(JSON.stringify("Inserted new Topic at: " + data.id))
    }

    // https://developers.cloudflare.com/workers/tutorials/postgres/

    {/* Header ======================================================================================================= */ }
    return (
        <main>
            <Grid.Container className="siteHeader">
                <Grid>
                    <img className="mylogo" src="/logo.png" alt="Sentimentana Logo" />
                </Grid>
                <Grid>
                    <span className="mytitle">Sentimentana</span>
                </Grid>
            </Grid.Container>
            <h3 className="teaser"><b>&nbsp;</b></h3>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/" >&nbsp;Home&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/topic" >&nbsp;Topic&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/auth" >&nbsp;Auth&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/convert" >&nbsp;Convert&nbsp;</a>
            <div style={{ color: "white", fontSize: "25px", backgroundColor: "#000000" }}>
                <table style={{ padding: "15px", margin: "15px" }}>
                    <tr><td style={{ padding: "5px", marginTop: "25px" }}>Add Topic</td></tr>
                    <tr><td><Input onChange={e => setTitle(e.target.value)} value={title} width="400px" type="text" /> Title</td></tr>
                    <tr><td><Input onChange={e => setUrl(e.target.value)} value={url} width="400px" type="text" /> Url</td></tr>
                    <tr><td><Input onChange={e => setFav(e.target.value)} value={fav} width="400px" type="text" /> Icon</td></tr>
                    <tr><td><Textarea onChange={e => setDesc(e.target.value)} value={desc} style={{ height: "100px", width: "385px" }} /> Description</td></tr>
                    <tr><td><Input onChange={e => setVote(e.target.value)} value={vote} width="400px" type="text" /> Votes</td></tr>
                    <tr><td><Input onChange={e => setRating(e.target.value)} value={rating} width="400px" type="text" /> Rating</td></tr>
                    <tr><td><Button style={{ marginTop: "20px", height: "30px", width: "400px" }} onClick={insertTopic}>Add Topic</Button></td></tr>
                    <tr><td style={{ color: "red" }}>{comment}</td></tr>
                    <tr><td>---------------</td></tr>
                    <tr><td>
                        <div className="contact" >Contact: <a href="mailto:info@sentimentana.com" target="_blank"
                            rel="noopener noreferrer">info@sentimentana.com</a>
                            <Spacer />Copyright TECH41 GmbH, Berlin, 1.2.2023 - V 1.0.11
                        </div>
                    </td></tr>
                </table>
            </div>

        </main>
    )
}
export default Topic