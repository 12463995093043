import { React, useState, useEffect } from 'react';
import {  Spacer, Grid, Input, Button, Textarea } from "@nextui-org/react";


// https://jwt.io/
// https://www.npmjs.com/package/@react-keycloak/web
// https://www.tabnine.com/code/javascript/functions/node-jose/Verifier/verify
function Convert() {

    const [unixTime, setUnixTime] = useState( (Date.now()/1000).toFixed(0))
    const [localTime, setLocalTime] = useState("")

    useEffect(() => {
        convertTime()
    }, []);

    function setNow () {
        let d = (Date.now() / 1000).toFixed(0)
        let time = timeConverter(d)
        setUnixTime(d)
        setLocalTime(time)
    }

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
      }

    function convertTime() {
        setLocalTime(timeConverter(unixTime))
    }

    {/* Header ======================================================================================================= */ }
    return (
        <main>
            <Grid.Container className="siteHeader">
                <Grid>
                    <img className="mylogo" src="/logo.png" alt="Sentimentana Logo" />
                </Grid>
                <Grid>
                    <span className="mytitle">Sentimentana</span>
                </Grid>
            </Grid.Container>
            <h3 className="teaser"><b>&nbsp;</b></h3>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/" >&nbsp;Home&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/topic" >&nbsp;Topic&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/auth" >&nbsp;Auth&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/convert" >&nbsp;Convert&nbsp;</a>
            
            <div style={{ color: "white", fontSize: "25px", backgroundColor: "#000000" }}>
                <table style={{ padding: "15px", margin: "15px" }}>
                    <tr><td style={{ padding: "5px", marginTop: "25px" }}>Convert time</td></tr>
                  
                    <tr><td><Input onChange={e => setUnixTime(e.target.value)} value={unixTime} width="600px" type="text" /> Unix time</td></tr>
                    <tr><td><Input onChange={e => setLocalTime(e.target.value)} value={localTime} width="600px" type="text" /> Local time</td></tr>
                    
                    <tr><td><Button style={{ marginTop: "20px", height: "30px", width: "200px" }} onPress={convertTime}> Convert</Button>
                    <Button style={{ marginTop: "20px", height: "30px", width: "200px" }} onPress={setNow}> now</Button>
                    </td></tr>
                    <tr><td>---</td></tr>
                   </table>

            </div>
        </main>
    )
}
export default Convert