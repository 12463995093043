import React from 'react';
import Home from './pages/Home';
import Topic from './pages/Topic';
import Auth from './pages/Auth';
import Convert from './pages/Convert';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools";
import ReactDOM from "react-dom";
import { BrowserRouter,Routes, Route} from "react-router-dom";

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
    100: "#000000",
    1000: "#FFFFFFFF",
  },
  initialColorMode: 'light',
  useSystemColorMode: false,
}


// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({ config,colors,
  styles: {
    global: (props) => ({
      body: {
        bg: mode('white'),
      }
    })
  },

})
export default theme

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/admin/topic" element={<Topic />}/>
        <Route path="/admin/auth" element={<Auth />}/>
        <Route path="/admin/convert" element={<Convert />}/>
        </Routes>
    </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); 



