import { React, useState, useEffect } from 'react';
import {  Spacer, Grid, Input, Button, Textarea } from "@nextui-org/react";
import jwt_decode from "jwt-decode";
import base64url from "base64url";
import * as jose from 'jose'

// https://jwt.io/
// https://www.npmjs.com/package/@react-keycloak/web
// https://www.tabnine.com/code/javascript/functions/node-jose/Verifier/verify
function Auth() {

    const keyconfig =  {
        url: 'https://key.auton.id:8443',
        realm: 'sentimentana',
        clientId: 'sentimentana',
        redirect_uri: 'https://*',
        flow: 'standard',
        'enable-cors': true,
        scope: 'offline_access'
    }


    const PUBLICKEY = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAudHl3O3lwlw8kxwyu800w7IpkvOBtcE6SD72Kaj3B3Ubn8iC0UnaTYrbcQRIOuMyX32XvsqLhLd60dK2td9aNVb3gr+l2k2N1R7g8BRBLMWc1vIUwmt0ci+KgfGuWH8EE3VcLo7dL0FK4iMXSNch2Dt5d4qzJ7qygFoowilbVQXid96evCIgdCmKyOpCsHjm3U3/P5QSIcm4VcBRidfZT7Q4s2ZiHKCTTy0Wt3TM+XJXS5T2gqEMQzttEri5I09DrBi8j1Zq9B5uEpQ0Lnx3D0MtYMvt/bfR2eag+1cMJQn3s16lZp2hESWzxRQVlC9nHntjNhs041xtU5MAXI1/AQIDAQAB
    -----END PUBLIC KEY-----`;

    const [token, setToken] = useState("Bearer ")
    const [username, setUsername] = useState("senti");
    const [password, setPassword] = useState("");
    const [comment, setComment] = useState("");
    const [validateText, setValidateText] = useState("");
    const [payload, setPayload] = useState("");

    useEffect(() => {
        //getHeaders()
    }, []);

    async function getHeaders() {
        var req = new XMLHttpRequest();
        req.open('GET', document.location, false);
        req.send(null);
        var headers = req.getAllResponseHeaders().toLowerCase();
    }

    async function validateTokenOffline(){
       let res = await  verifyToken(token)
    }

    //https://www.npmjs.com/package/jwt-decode
    async function verifyToken(token) {
        let jwt = token.substring(7)
        const ecPublicKey = await jose.importSPKI(PUBLICKEY,  'RS256')
        const { payload, protectedHeader } = await jose.jwtVerify(jwt, ecPublicKey)
        setPayload(JSON.stringify(payload))
        console.log(protectedHeader)
        console.log(payload)
    }

    async function validateToken(){
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            }
        };
        const response = await fetch('https://api.sentimentana.com/api/v0/validatetoken',requestOptions)
        setComment( response.status + ' - ' + new Date())
        if(response.status==200){
            const object = await response.text()
            setValidateText(object)
        }
    }

    async function createToken(){
        setComment('requested ' + new Date())
        let url = "https://api.sentimentana.com/api/v0/gettoken"
        let body =  JSON.stringify({username:username,password:password})
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: body
        };
        const response = await fetch(url, requestOptions);
        const data = await response.json();
       
       // let j = await rawResponse.json()
        setToken("Bearer " + data.access_token)
        setComment('received ' + new Date())
    }

    {/* Header ======================================================================================================= */ }
    return (
        <main>
            <Grid.Container className="siteHeader">
                <Grid>
                    <img className="mylogo" src="/logo.png" alt="Sentimentana Logo" />
                </Grid>
                <Grid>
                    <span className="mytitle">Sentimentana</span>
                </Grid>
            </Grid.Container>
            <h3 className="teaser"><b>&nbsp;</b></h3>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/" >&nbsp;Home&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/topic" >&nbsp;Topic&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/auth" >&nbsp;Auth&nbsp;</a>
            <a style={{ color: "blue", fontSize: "25px", backgroundColor: "#DDDDDD" }} href="/admin/convert" >&nbsp;Convert&nbsp;</a>
            <div style={{ color: "white", fontSize: "25px", backgroundColor: "#000000" }}>
                <table style={{ padding: "15px", margin: "15px" }}>
                    <tr><td style={{ padding: "5px", marginTop: "25px" }}>Create API token</td></tr>
                    <tr><td><Button style={{ marginTop: "20px", height: "30px", width: "200px" }} onPress={createToken}> Create new token</Button></td></tr>
                    <tr><td><Input onChange={e => setUsername(e.target.value)} value={username} width="600px" type="text" /> Username</td></tr>
                    <tr><td><Input onChange={e => setPassword(e.target.value)} value={password} width="600px" type="password" /> Password</td></tr>
                    <tr><td><Textarea onChange={e => setToken(e.target.value)} value={token} height="500px" width="800px"  /></td></tr>
                    <tr><td><Button style={{ marginTop: "20px", height: "30px", width: "200px" }} onPress={validateToken}> validate  token</Button></td></tr>
                    <tr><td style={{ color: "red" }}>{comment}</td></tr>
                    <tr><td><Textarea onChange={e => setValidateText(e.target.value)} value={validateText} width="600px" type="text" /> Validated User</td></tr>
                    <tr><td><Button style={{ marginTop: "20px", height: "30px", width: "200px" }} onPress={validateTokenOffline}> validate  token offline</Button></td></tr>
                    <tr><td><Textarea onChange={e => setPayload(e.target.value)} value={payload} width="600px" type="text" /> Token payload</td></tr>
                   </table>

            </div>
        </main>
    )
}
export default Auth